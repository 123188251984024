
import { computed, defineComponent, ref } from "vue";
import { Field } from 'vee-validate';
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "CodeSearch",
  components: {Field},
  props: {
    searchFor: {type: String},
    type: {type: String, required: true}
  },
  emits: ['selectCode'],
  setup(props, {emit}) {
    const typeRef = ref(props.type)
    const code = ref('');
    const searching = computed(() => store.state.LookupModule.searching);
    const searchData = computed(() => store.state.LookupModule.searchData);
    const search = () => {
      store.dispatch(Actions.LOOKUP_SEARCH, {type: typeRef.value, code: code.value})
    }
    const selectCode = (code) => {
      emit('selectCode', {code: code, searchFor: props.searchFor});
    }
    return {
      code,
      searchData,
      searching,
      search,
      selectCode,
    };
  }
})
