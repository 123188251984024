
import {computed, defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ProviderSearch from "@/views/provider/ProviderSearch.vue";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";
import {HandleState} from "@/core/composite/composite";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {PROVIDER_STATUS, PROVIDER_TYPE} from "@/core/config/Constant";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import CodeSearch from "@/views/provider/CodeSearch.vue";
import ProviderService from "@/core/services/ProviderService";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import * as Yup from "yup";

export default defineComponent({
  name: "ProviderForm",
  components: {
    FormErrorMessage,
    CodeSearch,
    DateTimeSelect, BaseRadio, BaseSelect, ProviderSearch, BaseModal, QuickAction, BaseForm, Field
  },
  props: {
    cazeId: {type: String},
    provider: {type: Object, required: true},
    providerId: {type: String}
  },
  emits: ['providerSaved'],
  setup(props, {emit}) {
    const refCazeId = ref(props.cazeId);
    const model = ref<any>(props.provider)
    model.value.cazeId = refCazeId.value
    const codeSearch = ref({type: 'ICD10CM', searchFor: ''});
    const submitting = ref(false);
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const validate = Yup.object().shape({
      name: Yup.string().label("Name").max(255),
      npi: Yup.string().label("NPI").max(10),
      mrNum: Yup.string().label("Medical Record#").max(100),
      accNum: Yup.string().label("Account#").max(20),
    });
    watch(() => props.provider, cb => {
      model.value = cb;
      model.value.cazeId = props.cazeId;
    })
    const saveProvider = () => {
      submitting.value = true
      if (props.providerId) {
        ProviderService.update(props.providerId, model.value).then(res => {
          emit('providerSaved', res)
        }).finally(() => {
          submitting.value = false
        })
      } else {
        ProviderService.create(model.value).then(res => {
          emit('providerSaved', res)
        }).finally(() => {
          submitting.value = false;
        })
      }
    }
    return {
      ...HandleState(),
      submitting,
      validate,
      states,
      model,
      countries,
      PROVIDER_STATUS,
      codeSearch,
      saveProvider,
      PROVIDER_TYPE,
    }

  },
  methods: {
    selectedProvider(provider) {
      this.model.name = provider.name;
      this.model.npi = provider.npi;
      this.model.address = provider.address;
      this.onCountrySelectedEvent(this.model.address.countryCode);
      const baseModal = this.$refs.refProviderSearch as typeof BaseModal
      baseModal.hideBaseModal()
    },

    onCodeSearch(searchFor) {
      this.codeSearch.searchFor = searchFor;
    },
    onSelectCode(data) {
      switch (data.searchFor) {
        case 'DIAG1':
          this.model.diagId1 = data.code.id
          this.model.diag1Code = data.code.code
          break;
        case 'DIAG2':
          this.model.diagId2 = data.code.id
          this.model.diag2Code = data.code.code
          break;
        case 'DIAG3':
          this.model.diagId3 = data.code.id
          this.model.diag3Code = data.code.code
          break;
        case 'DIAG4':
          this.model.diagId4 = data.code.id
          this.model.diag4Code = data.code.code
          break;
      }
      const baseModal = this.$refs.refSearchCodeModal as typeof BaseModal
      baseModal.hideBaseModal()
    },
    onCancel() {
      this.$router.go(-1)
    },
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name) this.model.address.stateName = name
    },
    onTypeSelect(type) {
      if (type === 'HOSPITAL') {
        this.model.status = 'ACTIVE';
      }
    },
    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) this.model.address.countryName = name;
    },
  }
})
