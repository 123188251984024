
import {computed, defineComponent, ref} from "vue";
import {Field} from "vee-validate";
import store from "../../store";
import {Actions} from "@/store/enums/StoreEnums";
import {ModelAddress} from "@/core/entity/IAddress";
import LocationService from "@/core/services/LocationService";

export default defineComponent({
  name: "ProviderSearch",
  components: {Field},
  emits: ['selectProvider'],
  setup(_, {emit}) {
    const terms = ref('');
    const searching = computed(() => store.state.ProviderModule.searching);
    const providerSearch = computed(() => store.state.ProviderModule.providerSearch);
    const search = () => {
      store.dispatch(Actions.PROVIDER_LIVE_SEARCH, terms.value);
    }
    const selectProvider = async (provider, index) => {
      const address = new ModelAddress();
      address.zip = providerSearch.value[2]['addr_practice.zip'][index]
      address.street = providerSearch.value[2]['addr_practice.line1'][index]
      address.street2 = providerSearch.value[2]['addr_practice.line2'][index]
      address.stateCode = providerSearch.value[2]['addr_practice.state'][index]
      address.countryCode = providerSearch.value[2]['addr_practice.country'][index]
      await LocationService.listByCode(address.countryCode).then(res => {
        const name = res.find((item) => item.code === address.stateCode)?.name
        if (name) address.stateName = name
      })
      address.cityName = providerSearch.value[2]['addr_practice.city'][index]
      const emitProvider = {name: provider[0], npi: provider[1], address: address};
      emit('selectProvider', emitProvider);
    }

    return {
      searching,
      terms,
      search,
      providerSearch,
      selectProvider,
    }
  }
})
